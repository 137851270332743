/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { dFix } from '@/utils/floatingPoint';

type MaybeString = string | number | null;

type WithStringInput = {
	string: string;
};

type WithSeparators = {
	decimalSeparator: string;
	thousandSeparator: string;
};

type WithPrecision = {
	precision: number;
};

type WithMinMax = {
	min?: number;
	max?: number;
};

const removeRestrictedCharacters = ({ string }: WithStringInput) =>
	string.replace(/[^0-9\.,]/g, '');

const displayThousands = ({ string, thousandSeparator }: WithStringInput & WithSeparators) =>
	string.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

const clampString = ({
	string,
	min,
	max,
	after,
}: WithStringInput & WithMinMax & { after?: string }) => {
	const numValue = parseFloat(string);
	const adjustedMax = max ? (after && dFix(max, 0) === max ? max - 1 : max) : max;
	const value = dFix(numValue, 0);

	return Math.max(
		Math.min(value, adjustedMax ?? Number.MAX_VALUE),
		min ?? Number.MIN_VALUE
	).toString();
};

const isValueZero = (value: string) => {
	const result = value === '0' ? true : false;
	return result;
};

const isValueBelowMin = (value: string, min: number | undefined) => {
	const result = min !== undefined && parseFloat(value) < min ? true : false;
	return result;
};

const isFractionalIncrementSku = (fractionalIncrementQtyLimit: number | undefined) =>
	fractionalIncrementQtyLimit !== undefined &&
	fractionalIncrementQtyLimit &&
	fractionalIncrementQtyLimit > 0
		? true
		: false;

const numberStringToPrecision = ({
	string,
	precision,
	decimalSeparator,
	thousandSeparator,
	min,
	max,
	fractionalIncrementQtyLimit,
}: WithStringInput &
	WithPrecision &
	WithSeparators &
	WithMinMax & { fractionalIncrementQtyLimit?: number }) => {
	const isFractional = isFractionalIncrementSku(fractionalIncrementQtyLimit);
	console.log('isFractional=' + isFractional);
	const [before, after] = string.split(decimalSeparator);
	const afterTrimmed = after?.substring(0, precision).trim() || '';
	const beforeIsZero = isValueZero(before.replaceAll(thousandSeparator, ''));
	const isBelowMin = isValueBelowMin(before + decimalSeparator + afterTrimmed, min);

	const beforeWithThousands = displayThousands({
		string:
			string.length > 0
				? beforeIsZero
					? '0'
					: clampString({
							string: before.replaceAll(thousandSeparator, ''),
							after: afterTrimmed,
							min,
							max,
						})
				: string,
		thousandSeparator,
		decimalSeparator,
	});

	return isBelowMin
		? min
		: after !== undefined && !beforeWithThousands.includes(decimalSeparator)
			? `${beforeWithThousands}${precision > 0 ? decimalSeparator : ''}${afterTrimmed}`
			: beforeWithThousands;
};

export const formatNumberValue = ({
	value,
	precision,
	decimalSeparator,
	thousandSeparator,
	min,
	max,
	fractionalIncrementQtyLimit,
}: WithPrecision &
	WithSeparators &
	WithMinMax & { fractionalIncrementQtyLimit?: number } & {
		value: MaybeString;
	}) =>
	numberStringToPrecision({
		string: removeRestrictedCharacters({ string: value?.toString() || '' }),
		precision,
		decimalSeparator,
		thousandSeparator,
		min,
		max,
		fractionalIncrementQtyLimit,
	});
